import { ListLayout } from "../../../layouts";
import { EtiquetasService, history, notification } from "../../../services";
import { DataGrid } from "../../../components";
import { FormFiltroAvancado } from "./components";
import { filtrosUrl, PermisoesHelper } from "../../../helpers";
import { useEffect, useState } from "react";
import { cacheApi, responseErros } from "../../../utils";
import { useCacheContext } from "../../../contexts/cache.context";

const ListEtiquetasView = () => {
  const { cacheDto, setCacheDto } = useCacheContext();
  const [etiquetas, setEtiquetas] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const permissoesHelper = new PermisoesHelper();
  const etiquetasService = new EtiquetasService();
  const colunas = [
    {
      field: "ativado",
      headerName: "Status",
      flex: 100,
      valueGetter: (params) =>
        params.value == true ? "Ativado" : "Desativado",
    },
    {
      field: "descricao",
      headerName: "Etiquetas",
      flex: 700,
      filtrar: true,
    },
    {
      field: "cor",
      headerName: "Cor",
      flex: 200,
      renderCell: (params) => {
        return (
          <div
            className="d-flex w-full rounded-pill"
            style={{ backgroundColor: params.value, width: 50, height: 32 }}
          ></div>
        );
      },
    },
  ];

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscarEtiquetas(filtros);
  }, []);

  const buscarEtiquetas = async function (filter) {
    setLoading(true);
    const result = await etiquetasService.getAll(filter);
    if (!result.isAxiosError) {
      setEtiquetas(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const onClickCadastrarEtiqueta = function () {
    history.push("/estoque/etiquetas/create");
  };

  const onClickEditEtiqueta = function (id) {
    history.push(`/estoque/etiquetas/${id}`);
  };

  const onClickDeleteMeioPagamento = async function (id) {
    const data = etiquetas.find((item) => item.id === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${data.descricao}`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await etiquetasService.deletar(id);
      if (!result.isAxiosError) {
        buscarEtiquetas();
        notification.deletadoSucesso();
      } else {
        responseErros(result);
      }
      setLoading(false);
    }
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscarEtiquetas(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    const label = filtrosUrl.getUrl(filter);
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: etiquetasService.getAllFiltroAvancado({
        ...filtrosAvancadosTemp,
        restritiva: true,
        porIntervalo,
      }),
    };
    const result = await cacheApi(label, cacheBody);
    if (!result.isAxiosError) {
      setEtiquetas(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  return (
    <ListLayout
      title="Etiquetas"
      loading={loading}
      onClickCadastrar={onClickCadastrarEtiqueta}
      permissaoButton={permissoesHelper.temPermisao(
        "meios-pagamento-cadastrar"
      )}
    >
      <DataGrid
        rows={etiquetas}
        columns={colunas}
        onClickEdit={onClickEditEtiqueta}
        onClickDelete={onClickDeleteMeioPagamento}
        visualizarShow={permissoesHelper.temPermisao("etiquetas-visualizar")}
        editShow={permissoesHelper.temPermisao("etiquetas-editar")}
        deleteShow={permissoesHelper.temPermisao("etiquetas-excluir")}
        paginationMode={"server"}
        rowCount={rowCount}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
    </ListLayout>
  );
};

export default ListEtiquetasView;
