import { useEffect, useState } from "react";
import { AddLayout } from "../../../layouts";
import { responseErros, userLogs, voltar } from "../../../utils";
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "../../../components";
import { InputErros, PermisoesHelper, value } from "../../../helpers";
import "./etiquetas.css";
import { EtiquetasService, notification } from "../../../services";
import etiquetasValidator from "./middlewares/etiquetas.validator";
import { useImportContext } from "../../../contexts/import.context";

const AtivadoComponent = ({ etiqueta, setEtiqueta }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={etiqueta?.ativado ? etiqueta.ativado : false}
          onChange={() =>
            setEtiqueta((etiqueta) => {
              return {
                ...etiqueta,
                ativado: !etiqueta?.ativado,
              };
            })
          }
          name="ativado"
          color="primary"
        />
      }
      label={etiqueta?.ativado ? "Ativado" : "Desativado"}
    />
  );
};

const etiquetasHex = [
  "#F50007",
  "#0EF54B",
  "#1F6223",
  "#F56400",
  "#6C0084",
  "#84002D",
  "#4E5EF2",
  "#007584",
  "#000E84",
];

const AddEtiquestasView = ({ match }) => {
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const [etiqueta, setEtiqueta] = useState({
    ativado: true,
    tipo: "PRODUTO",
    cor: etiquetasHex[Math.floor(Math.random() * etiquetasHex.length)],
  });
  const [loading, setLoading] = useState(false);
  const [userLog, setUserLog] = useState({});
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const etiquetasService = new EtiquetasService();
  const permissoesHelper = new PermisoesHelper();

  useEffect(() => {
    if (!id) return;
    buscarEtiqueta();
  }, []);

  const buscarEtiqueta = async () => {
    setLoading(true);
    const result = await etiquetasService.getById(id);
    if (!result.isAxiosError) {
      setEtiqueta(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(etiqueta);
    setUserLog(logs);
    setLoading(false);
  }

  function handleChangeColor(cor) {
    setEtiqueta({
      ...etiqueta,
      cor,
    });
  }

  function handleInputChange(e) {
    setEtiqueta({
      ...etiqueta,
      [e.target.name]: e.target.value,
    });
  }

  const handleSubmit = async (indiceSelecionado) => {
    setLoading(true);
    etiquetasValidator
      .validate(etiqueta, { abortEarly: false })
      .then(async () => {
        if (id) {
          const response = await etiquetasService.atualizar(id, etiqueta);
          setLoading(false);
          if (!response.isAxiosError) {
            buscarEtiqueta();
            notification.alteracaoSucesso();
            initialCollapses();
            voltar();
          } else responseErros(response);
        } else {
          const response = await etiquetasService.cadastrar(etiqueta);
          setLoading(false);
          if (!response.isAxiosError) {
            notification.cadastroSucesso();
            if (indiceSelecionado === 0) {
              initialCollapses();
              voltar();
              return;
            }
          } else responseErros(response);
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  return (
    <AddLayout
      id={id}
      title={etiqueta?.id ? "Editar Etiqueta" : "Cadastrar Etiqueta"}
      onClickSalvar={handleSubmit}
      loading={loading}
      userLog={etiqueta?.id ? userLog : null}
      onClickLog={onClickLog}
      showUpdate={permissoesHelper.temPermisao("meios-pagamento-editar")}
      actions={
        <AtivadoComponent etiqueta={etiqueta} setEtiqueta={setEtiqueta} />
      }
    >
      <Grid container spacing={2} className="h-100">
        <Grid item xs={3}>
          <Select
            id="tipo"
            name="tipo"
            label="Tipo"
            margin="normal"
            value={value.text(etiqueta?.tipo)}
            onChange={(event) => handleInputChange(event)}
          >
            <MenuItem value={"PESSOA"}>Pessoa</MenuItem>
            <MenuItem value={"PRODUTO"}>Produto</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={9}>
          <TextField
            id="descricao"
            name="descricao"
            label="Etiqueta"
            variant="outlined"
            margin="normal"
            value={etiqueta?.descricao ? etiqueta.descricao : ""}
            onChange={(e) => {
              handleInputChange(e);
            }}
            fullWidth
            required
            error={inputErros.get("descricao")}
          />
        </Grid>
        <Grid item xs={12} className="">
          <h4
            className="text-center font-weight-bold text-uppercase"
            style={{ fontWeight: 800 }}
          >
            Escolha uma cor
          </h4>
          <p className="text-center font-weight-bold">
            clique para selecione uma cor
          </p>
          <div className="w-100 d-flex justify-content-center flex-wrap">
            {etiquetasHex.map((cor, index) => (
              <div key={index} onClick={() => handleChangeColor(cor)}>
                <i
                  className="ph-fill ph-bookmark-simple etiqueta-bookmark"
                  style={{
                    color: cor,
                    opacity: cor === etiqueta.cor ? 1 : 0.2,
                    "-webkit-text-stroke":
                      cor === etiqueta.cor ? "3px black" : "",
                  }}
                />
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </AddLayout>
  );
};

export default AddEtiquestasView;
