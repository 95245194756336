import {
  MainView,
  ConfiguracoesView,
  CentralUsuario,
  AddEmpresaView,
  AddPessoaView,
  ListPessoasView,
  AddGrupoPessoasView,
  ListGruposPessoasView,
  AddClassificacaoFinanceiraView,
  ListClassificacaoFinanceiraView,
  ListUsuariosView,
  AddUsuariosView,
  ListGrupoProdutosView,
  ListProdutosView,
  AddProdutoView,
  ListTabelaPrecoView,
  AddTabelaPrecoView,
  ListModeloGradeView,
  AddModeloGradeView,
  ListRegraDescontoView,
  AddRegraDescontoView,
  AddMeioPagamentoView,
  ListMeioPagamentoView,
  AddCondicaoPagamentoView,
  ListCondicoesPagamentoView,
  ListGruposTributarioView,
  AddOperacoesFiscaisView,
  ListOperacoesFiscaisView,
  ListModeloTributacaoView,
  AddModeloTributacaoView,
  MonitorFiscalView,
  ListNfSaidaView,
  AddNfSaidaView,
  ListPedidosView,
  AddPedidosView,
  ListOrcamentosView,
  AddOrcamentosView,
  ListContasReceber,
  AddContasReceber,
  ListBancosCaixasView,
  AddBancosCaixasView,
  ListContasPagarView,
  AddContasPagarView,
  CaixaView,
  ListPlanosFinanceirosView,
  ListCentroCustoView,
  ListNfEntradaView,
  AddNfEntradaView,
  ListPerfilsUsuariosView,
  AddPerfilUsuarioView,
  ListDocumentoFreteView,
  AddDocumentoFreteView,
  RelatoriosEstoque,
  RelatoriosFaturamento,
  RelatoriosFinanceiro,
  ContadorNfEntradaView,
  ContadorContasReceberView,
  MeuUsuarioView,
  ListSaldoInicialView,
  AddSaldoInicialView,
  ImportarXmlView,
  AddEtiquestasView,
  ListEtiquetasView,
} from "../views";
import { MainLayout } from "../layouts";

const CurrentRoutes = [
  {
    component: MainView,
    exact: true,
    layout: MainLayout,
    path: "/dashboard",
    title: "Dashboard",
    podeExibirNaBusca: true,
    descricao: "Dashboards e informações importantes sobre sua empresa",
  },
  {
    component: ConfiguracoesView,
    exact: true,
    layout: MainLayout,
    path: "/painel-controle/configuracoes",
    title: "Configurações",
    podeExibirNaBusca: true,
    descricao: "Editar configurações importantes sobre o sistema",
  },
  {
    component: ListPessoasView,
    exact: true,
    layout: MainLayout,
    path: "/integracao/pessoas",
    title: "Listagem de pessoas",
    podeExibirNaBusca: true,
    descricao:
      "Veja o cadastro de clientes, colaboradores, fornecedores, etc...",
  },
  {
    component: AddPessoaView,
    exact: false,
    layout: MainLayout,
    path: "/integracao/pessoas/create",
    title: "Cadastro de Pessoas",
    podeExibirNaBusca: true,
    descricao: "Crie um novo cliente, colaborador, fornecedor, etc...",
  },
  {
    component: AddPessoaView,
    exact: false,
    layout: MainLayout,
    path: "/integracao/pessoas/:id",
    title: "Edição de Pessoas",
    podeExibirNaBusca: false,
    descricao:
      "Faça a edição de um cliente, colaborador, fornecedor, etc... já existente",
  },
  {
    component: ListUsuariosView,
    exact: true,
    layout: MainLayout,
    path: "/painel-controle/usuarios",
    title: "Listagem de Usuários",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de grupos usuários cadastrados",
  },
  {
    component: AddUsuariosView,
    exact: false,
    layout: MainLayout,
    path: "/painel-controle/usuarios/create",
    title: "Criação de Usuários",
    podeExibirNaBusca: true,
    descricao: "Crie um novo usuário...",
  },
  {
    component: AddUsuariosView,
    exact: false,
    layout: MainLayout,
    path: "/painel-controle/usuarios/:id",
    title: "Edição de Usuários",
    podeExibirNaBusca: false,
    descricao: "Faça a edição de um usuário... já existente",
  },
  {
    component: MeuUsuarioView,
    exact: false,
    layout: MainLayout,
    path: "/meu-usuario",
    title: "Meu Usuário",
    podeExibirNaBusca: true,
    temPermissao: false,
    descricao: "Gerencie seu perfil de usuário",
  },
  {
    component: ListGruposPessoasView,
    exact: true,
    layout: MainLayout,
    path: "/integracao/grupo-pessoas",
    title: "Listagem de grupo de pessoas",
    podeExibirNaBusca: true,
    descricao:
      "Veja a listagem de grupos de pessoas, colaboradores, fornecedores, etc...",
  },
  {
    component: AddGrupoPessoasView,
    exact: false,
    layout: MainLayout,
    path: "/integracao/grupo-pessoas/create",
    title: "Cadastro de grupo de pessoas",
    podeExibirNaBusca: true,
    descricao:
      "Crie um novo grupo de pessoas, colaboradores, fornecedores, etc...",
  },
  {
    component: AddGrupoPessoasView,
    exact: false,
    layout: MainLayout,
    path: "/integracao/grupo-pessoas/:id",
    title: "Edição de grupo de pessoas",
    podeExibirNaBusca: false,
    descricao:
      "Faça a edição de um grupo de pessoas, colaboradores, fornecedores, etc... já existente",
  },
  {
    component: AddEmpresaView,
    exact: false,
    layout: MainLayout,
    path: "/painel-controle/empresas/create",
    title: "Criar Empresa",
    podeExibirNaBusca: false,
    descricao: "Crie sua empresa",
  },
  {
    component: AddEmpresaView,
    exact: false,
    layout: MainLayout,
    path: "/painel-controle/empresas/:id",
    title: "Editar minha Empresa",
    podeExibirNaBusca: true,
    descricao: "Altere alguma informação de sua empresa",
  },
  {
    component: CentralUsuario,
    exact: false,
    layout: MainLayout,
    path: "/painel-controle/central-usuario",
    title: "Central do usuário",
    podeExibirNaBusca: true,
    descricao: "Altere dados do usuário e configurações da plataforma",
  },
  {
    component: ListGrupoProdutosView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/grupo-produtos",
    title: "Grupos de produtos",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de grupos de produtos",
  },
  {
    component: ListProdutosView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/produtos",
    title: "Listagem de produtos",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de produtos",
  },
  {
    component: AddProdutoView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/produtos/create",
    title: "Cadastro de produtos",
    podeExibirNaBusca: true,
    descricao: "Crie um novo produtos",
  },
  {
    component: ListTabelaPrecoView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/tabelas-preco",
    title: "Listagem de tabelas de preço",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de tabelas de preço",
  },
  {
    component: AddTabelaPrecoView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/tabelas-preco/create",
    title: "Cadastro de tabela de preço",
    podeExibirNaBusca: true,
    descricao: "Crie uma nova tabela de preço",
  },
  {
    component: AddTabelaPrecoView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/tabelas-preco/:id",
    title: "Edição de tabela de preço",
    podeExibirNaBusca: false,
    descricao: "Edite uma tabela de preço",
  },
  {
    component: AddProdutoView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/produtos/:id",
    title: "Edição de produtos",
    podeExibirNaBusca: true,
    descricao: "Faça a edição de um produtos",
  },
  {
    component: ListModeloGradeView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/modelos-grade",
    title: "Listagem de modelos de grade",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de modelos de grade",
  },
  {
    component: AddModeloGradeView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/modelos-grade/create",
    title: "Cadastro de modelo de grade",
    podeExibirNaBusca: true,
    descricao: "Crie um novo modelo de grade",
  },
  {
    component: AddModeloGradeView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/modelos-grade/:id",
    title: "Edição de modelo de grade",
    podeExibirNaBusca: false,
    descricao: "Edite um modelo de grade",
  },
  {
    component: ListRegraDescontoView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/regras-desconto",
    title: "Listagem de regras de desconto",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de regras de desconto",
  },
  {
    component: AddRegraDescontoView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/regras-desconto/create",
    title: "Cadastro de regra de desconto",
    podeExibirNaBusca: true,
    descricao: "Crie uma nova regra de desconto",
  },
  {
    component: AddRegraDescontoView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/regras-desconto/:id",
    title: "Edição de regra de desconto",
    podeExibirNaBusca: false,
    descricao: "Edite uma regra de desconto",
  },
  {
    component: ListClassificacaoFinanceiraView,
    exact: true,
    layout: MainLayout,
    path: "/financeiro/classificacoes-financeiras",
    title: "Listagem das classificacoes financeiras",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem das classificacoes financeiras",
  },
  {
    component: AddClassificacaoFinanceiraView,
    exact: false,
    layout: MainLayout,
    path: "/financeiro/classificacoes-financeiras/create",
    title: "Cadastro de classificacao",
    podeExibirNaBusca: true,
    descricao: "Crie uma nova classificação financeira",
  },
  {
    component: AddClassificacaoFinanceiraView,
    exact: false,
    layout: MainLayout,
    path: "/financeiro/classificacoes-financeiras/:id",
    title: "Edição de grupo de pessoas",
    podeExibirNaBusca: false,
    descricao: "Faça a edição de uma classificação financeira já existente",
  },
  {
    component: ListMeioPagamentoView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/meios-pagamento",
    title: "Listagem de meios de pagamento",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de meios de pagamento",
  },
  {
    component: AddMeioPagamentoView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/meios-pagamento/create",
    title: "Cadastro de meio de pagamento",
    podeExibirNaBusca: true,
    descricao: "Crie um novo meio de pagamento",
  },
  {
    component: AddMeioPagamentoView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/meios-pagamento/:id",
    title: "Edição de meio de pagamento",
    podeExibirNaBusca: false,
    descricao: "Edite um meio de pagamento",
  },
  {
    component: ListCondicoesPagamentoView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/condicoes-pagamento",
    title: "Listagem de condições de pagamento",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de condições de pagamento",
  },
  {
    component: AddCondicaoPagamentoView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/condicoes-pagamento/create",
    title: "Cadastro de condição de pagamento",
    podeExibirNaBusca: true,
    descricao: "Crie uma nova condição de pagamento",
  },
  {
    component: AddCondicaoPagamentoView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/condicoes-pagamento/:id",
    title: "Edição de condição de pagamento",
    podeExibirNaBusca: false,
    descricao: "Edite uma condição de pagamento",
  },
  {
    component: ListGruposTributarioView,
    exact: true,
    layout: MainLayout,
    path: "/fiscal/grupos-tributario",
    title: "Listagem de grupos tributário",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de grupos tributário",
  },
  {
    component: ListOperacoesFiscaisView,
    exact: true,
    layout: MainLayout,
    path: "/fiscal/operacoes-fiscais",
    title: "Listagem de operações fiscais",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de operações fiscais",
  },
  {
    component: AddOperacoesFiscaisView,
    exact: true,
    layout: MainLayout,
    path: "/fiscal/operacoes-fiscais/create",
    title: "Cadastro de operação fiscal",
    podeExibirNaBusca: true,
    descricao: "Crie uma nova operação fiscal",
  },
  {
    component: AddOperacoesFiscaisView,
    exact: true,
    layout: MainLayout,
    path: "/fiscal/operacoes-fiscais/:id",
    title: "Edição de operação fiscal",
    podeExibirNaBusca: false,
    descricao: "Edite uma operação fiscal",
  },
  {
    component: ListModeloTributacaoView,
    exact: true,
    layout: MainLayout,
    path: "/fiscal/modelos-tributacao",
    title: "Listagem de operações fiscais",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de modelos de tributacao",
  },
  {
    component: AddModeloTributacaoView,
    exact: true,
    layout: MainLayout,
    path: "/fiscal/modelos-tributacao/create",
    title: "Cadastro de Modelo de Tributação",
    podeExibirNaBusca: true,
    descricao: "Crie um novo modelo de tributação",
  },
  {
    component: AddModeloTributacaoView,
    exact: true,
    layout: MainLayout,
    path: "/fiscal/modelos-tributacao/:id",
    title: "Edição de Modelo de Tributação",
    podeExibirNaBusca: false,
    descricao: "Edite um modelo de tributação",
  },
  {
    component: MonitorFiscalView,
    exact: true,
    layout: MainLayout,
    path: "/fiscal/monitor-fiscal",
    title: "Edição de Modelo de Tributação",
    podeExibirNaBusca: false,
    descricao: "Edite um modelo de tributação",
  },
  {
    component: ListNfSaidaView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/nf-saida",
    title: "Listagem de nf de saida",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de nf de saida",
  },
  {
    component: AddNfSaidaView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/nf-saida/create",
    title: "Cadastro de nf de saida",
    podeExibirNaBusca: true,
    descricao: "Crie um novo nf de saida",
  },
  {
    component: AddNfSaidaView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/nf-saida/:id",
    title: "Edição de nf de saida",
    podeExibirNaBusca: false,
    descricao: "Edite um nf de saida",
  },
  {
    component: ListBancosCaixasView,
    exact: true,
    layout: MainLayout,
    path: "/financeiro/bancos-caixas",
    title: "Listagem de bancos e caixas",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de bancos e caixas",
  },
  {
    component: AddBancosCaixasView,
    exact: true,
    layout: MainLayout,
    path: "/financeiro/bancos-caixas/create",
    title: "Cadastro de conta",
    podeExibirNaBusca: true,
    descricao: "Crie um novo conta",
  },
  {
    component: AddBancosCaixasView,
    exact: true,
    layout: MainLayout,
    path: "/financeiro/bancos-caixas/:id",
    title: "Edição de conta",
    podeExibirNaBusca: false,
    descricao: "Edite um conta",
  },
  {
    component: ListPedidosView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/pedidos",
    title: "Listagem de pedidos",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de pedidos",
  },
  {
    component: AddPedidosView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/pedidos/create",
    title: "Cadastro de pedidos",
    podeExibirNaBusca: true,
    descricao: "Crie um novo pedido",
  },
  {
    component: AddPedidosView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/pedidos/:id",
    title: "Edição de pedidos",
    podeExibirNaBusca: true,
    descricao: "Edite um pedido",
  },
  {
    component: ListOrcamentosView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/orcamentos",
    title: "Listagem de orçamento",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de orçamento",
  },
  {
    component: AddOrcamentosView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/orcamentos/create",
    title: "Cadastro de orçamento",
    podeExibirNaBusca: true,
    descricao: "Crie um novo orçamento",
  },
  {
    component: AddOrcamentosView,
    exact: true,
    layout: MainLayout,
    path: "/faturamento/orcamentos/:id",
    title: "Edição de orçamento",
    podeExibirNaBusca: true,
    descricao: "Edite um orçamento",
  },
  {
    component: ListContasReceber,
    exact: true,
    layout: MainLayout,
    path: "/financeiro/contas-receber",
    title: "Listagem de contas a receber",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de contas a receber",
  },
  {
    component: AddContasReceber,
    exact: true,
    layout: MainLayout,
    path: "/financeiro/contas-receber/create",
    title: "Cadastro de conta a receber",
    podeExibirNaBusca: true,
    descricao: "Crie um nova conta a receber",
  },
  {
    component: AddContasReceber,
    exact: true,
    layout: MainLayout,
    path: "/financeiro/contas-receber/:id",
    title: "Edição de conta a receber",
    podeExibirNaBusca: false,
    descricao: "Faça a edição de uma conta a receber",
  },
  {
    component: ListContasPagarView,
    exact: true,
    layout: MainLayout,
    path: "/financeiro/contas-pagar",
    title: "Listagem de contas a pagar",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de contas a pagar",
  },
  {
    component: AddContasPagarView,
    exact: true,
    layout: MainLayout,
    path: "/financeiro/contas-pagar/create",
    title: "Cadastro de conta a pagar",
    podeExibirNaBusca: true,
    descricao: "Crie uma nova conta a pagar",
  },
  {
    component: AddContasPagarView,
    exact: true,
    layout: MainLayout,
    path: "/financeiro/contas-pagar/:id",
    title: "Edição de conta a pagar",
    podeExibirNaBusca: false,
    descricao: "Edite uma conta a pagar",
  },
  {
    component: CaixaView,
    exact: true,
    layout: MainLayout,
    path: "/financeiro/caixa",
    title: "Caixa",
    podeExibirNaBusca: true,
    descricao: "Cadastro do Caixa",
  },
  {
    component: ListPlanosFinanceirosView,
    exact: true,
    layout: MainLayout,
    path: "/financeiro/planos-financeiros",
    title: "Planos Financeiros",
    podeExibirNaBusca: true,
    descricao: "Listagem de Planos Financeiros",
  },
  {
    component: ListCentroCustoView,
    exact: true,
    layout: MainLayout,
    path: "/financeiro/centro-custo",
    title: "Listagem de centros de custo",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de centros de custo",
  },
  {
    component: ListNfEntradaView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/nf-entrada",
    title: "Listagem de NF de entrada",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de NFs de entrada",
  },
  {
    component: AddNfEntradaView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/nf-entrada/create",
    title: "Cadastro de NF de entrada",
    podeExibirNaBusca: true,
    descricao: "Crie uma nova NF de entrada",
  },
  {
    component: AddNfEntradaView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/nf-entrada/:id",
    title: "Edição de NF de entrada",
    podeExibirNaBusca: false,
    descricao: "Edite uma NF de entrada",
  },
  {
    component: ListDocumentoFreteView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/documentos-frete",
    title: "Listagem de documentos de frete",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de documentos de frete",
  },
  {
    component: AddDocumentoFreteView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/documentos-frete/create",
    title: "Cadastro de documento de frete",
    podeExibirNaBusca: true,
    descricao: "Crie um novo documento de frete",
  },
  {
    component: AddDocumentoFreteView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/documentos-frete/:id",
    title: "Edição de documento de frete",
    podeExibirNaBusca: false,
    descricao: "Edite uma documento de frete",
  },
  {
    component: RelatoriosFaturamento,
    exact: true,
    layout: MainLayout,
    path: "/relatorios-faturamento",
    title: "Listagem de relatórios de faturamento",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de relatórios de faturamento",
  },
  {
    component: RelatoriosFinanceiro,
    exact: true,
    layout: MainLayout,
    path: "/relatorios-financeiro",
    title: "Listagem de relatórios de financeiro",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de relatórios de financeiro",
  },
  {
    component: RelatoriosEstoque,
    exact: true,
    layout: MainLayout,
    path: "/relatorios-estoque",
    title: "Listagem de relatórios de estoque",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de relatórios de estoque",
  },
  {
    component: ContadorContasReceberView,
    exact: true,
    layout: MainLayout,
    path: "/contador/contas-receber",
    title: "Listagem de relatórios de contas a receber para o contador",
    podeExibirNaBusca: true,
    descricao:
      "Veja a listagem de relatórios de contas a receber para o contador",
  },
  {
    component: ListPerfilsUsuariosView,
    exact: true,
    layout: MainLayout,
    path: "/painel-controle/perfis-usuarios",
    title: "Listagem de perfil de usuarios",
    podeExibirNaBusca: false,
    descricao: "Veja a listagem de perfil de usuarios",
  },
  {
    component: AddPerfilUsuarioView,
    exact: true,
    layout: MainLayout,
    path: "/painel-controle/perfis-usuarios/create",
    title: "Cadastro de perfil de usuario",
    podeExibirNaBusca: false,
    descricao: "Crie um novo perfil de usuario",
  },
  {
    component: AddPerfilUsuarioView,
    exact: true,
    layout: MainLayout,
    path: "/painel-controle/perfis-usuarios/:id",
    title: "Edição de perfil de usuario",
    podeExibirNaBusca: false,
    descricao: "Edite um perfil de usuario",
  },
  {
    component: ContadorNfEntradaView,
    exact: true,
    layout: MainLayout,
    path: "/contador/nf-entrada",
    title: "Contador: NF Entrada",
    podeExibirNaBusca: true,
    descricao: "Relatório NF entrada contador",
  },
  {
    component: ListSaldoInicialView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/saldo-inicial",
    title: "Listagem de ajuste de estoque",
    podeExibirNaBusca: true,
    descricao: "Veja a listagem de ajuste de estoque",
  },
  {
    component: AddSaldoInicialView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/saldo-inicial/create",
    title: "Cadastro de ajuste de estoque",
    podeExibirNaBusca: true,
    descricao: "Crie um ajuste de estoque",
  },
  {
    component: AddSaldoInicialView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/saldo-inicial/:id",
    title: "Visualizar ajuste estoque",
    podeExibirNaBusca: false,
    descricao: "Visualize um ajuste de estoque",
  },
  {
    component: ImportarXmlView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/importacao-xml",
    title: "Importação XML",
    podeExibirNaBusca: false,
    descricao: "Importação XML",
  },
  {
    component: ListEtiquetasView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/etiquetas",
    title: "Listagem de etiquetas",
    podeExibirNaBusca: false,
    descricao: "Visualize uma lista de etiquetas",
  },
  {
    component: AddEtiquestasView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/etiquetas/create",
    title: "Cadastro de etiqueta",
    podeExibirNaBusca: false,
    descricao: "Crie uma etiqueta",
  },
  {
    component: AddEtiquestasView,
    exact: true,
    layout: MainLayout,
    path: "/estoque/etiquetas/:id",
    title: "Visualizar etiqueta",
    podeExibirNaBusca: false,
    descricao: "Visualize uma etiqueta",
  },
];

export default CurrentRoutes;
