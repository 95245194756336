import { parse } from "date-fns";
export class Pessoa {
  avatar = null;
  naturezaSocial = null;
  nomeRazaoSocial = null;
  apelidoFantasia = null;
  dataAbertura = null;
  dataNascimento = null;
  cnpjCpf = null;
  inscricaoEstadual = null;
  inscricaoEstadualRural = null;
  rg = null;
  contato = null;
  email = null;
  telefone = null;
  celular = null;
  tiposPessoas = [];
  idTiposPessoa = [];
  imagemOriginal = null;
  observacao = null;
  ativado = true;
  obrigatoriedadeCnpjCpf = null;
  etiquetasPessoa = [];
  enderecos = [];
  cliente = new Cliente();
  fornecedor = new Fornecedor();
  transportadora = new Transportadora();
  colaborador = new Colaborador();
  prestadorServico = new PrestadorServico();

  constructor(
    formPessoa,
    pessoa,
    cliente,
    fornecedor,
    transportadora,
    colaborador,
    prestadorServico
  ) {
    this.avatar = pessoa.avatar;
    this.naturezaSocial = pessoa?.naturezaSocial
      ? pessoa?.naturezaSocial
      : formPessoa?.naturezaSocial;
    this.nomeRazaoSocial = formPessoa?.nomeRazaoSocial?.toUpperCase();
    this.apelidoFantasia = formPessoa?.apelidoFantasia?.toUpperCase();
    this.dataAbertura = pessoa?.dataAbertura
      ? parse(pessoa.dataAbertura?.slice(0, 10), "yyyy-MM-dd", new Date())
      : null;
    this.dataNascimento = pessoa?.dataNascimento
      ? parse(pessoa.dataNascimento.slice(0, 10), "yyyy-MM-dd", new Date())
      : null;
    this.cnpjCpf = pessoa.cnpjCpf;
    this.inscricaoEstadual = formPessoa.inscricaoEstadual
      ? formPessoa.inscricaoEstadual?.toUpperCase()
      : null;
    this.inscricaoEstadualRural = formPessoa.inscricaoEstadualRural
      ? formPessoa.inscricaoEstadualRural.toUpperCase()
      : null;
    this.rg = formPessoa?.rg ? formPessoa.rg.toUpperCase() : null;
    this.contato = formPessoa?.contato
      ? formPessoa?.contato.toUpperCase()
      : null;
    this.email = pessoa.email;
    this.telefone = formPessoa.telefone
      ? formPessoa.telefone.replace(/\D+/g, "")
      : null;
    this.celular = formPessoa.celular
      ? formPessoa.celular.replace(/\D+/g, "")
      : null;
    this.tiposPessoas = pessoa.tiposPessoas;
    this.idTiposPessoa = pessoa?.tiposPessoas
      ? pessoa.tiposPessoas.map((tipo) => tipo.id)
      : [];
    this.imagemOriginal =
      pessoa?.id && pessoa?.avatar ? pessoa.avatar.split("/")[4] : null;
    this.observacao = formPessoa?.observacao ?? "";
    this.ativado = pessoa.ativado;
    this.obrigatoriedadeCnpjCpf = pessoa.obrigatoriedadeCnpjCpf;
    this.enderecos = pessoa?.enderecos
      ? pessoa.enderecos.map((endereco) => new Endereco(endereco))
      : [];
    this.etiquetasPessoa = pessoa.etiquetasPessoa;
    this.cliente = new Cliente(cliente);
    this.fornecedor = new Fornecedor(fornecedor);
    this.transportadora = new Transportadora(transportadora);
    this.colaborador = new Colaborador(colaborador);
    this.prestadorServico = new PrestadorServico(prestadorServico);
  }

  setAvatar(avatar) {
    this.avatar = avatar;
  }

  setPessoaEtiquetasIds(listEtiquetas) {
    if (listEtiquetas?.length) {
      this.etiquetasPessoa = listEtiquetas.map((item) => item.id);
    }
  }

  setCnpjCpf(id, pessoa, formPessoa) {
    if (id) {
      if (pessoa.cnpjCpf) {
        this.cnpjCpf = pessoa.cnpjCpf;
      } else {
        this.cnpjCpf = formPessoa.cnpjCpf?.replace(/\D+/g, "");
      }
    } else {
      this.email =
        formPessoa.email && formPessoa.email !== "" ? formPessoa.email : null;
      this.cnpjCpf = formPessoa.cnpjCpf?.replace(/\D+/g, "");
    }
  }

  setInformacoesBuscaCnpj(buscaCnpjRealizada, pessoa) {
    if (buscaCnpjRealizada) {
      this.nomeRazaoSocial = pessoa?.nomeRazaoSocial.toUpperCase();
      this.apelidoFantasia = pessoa?.apelidoFantasia.toUpperCase();
      this.email = pessoa?.email;
    }
  }
}

class Endereco {
  cep = null;
  logradouro = null;
  numero = null;
  bairro = null;
  complemento = null;
  descricao = null;
  padrao = null;
  cidadeId = null;
  constructor(endereco) {
    this.id = endereco?.id;
    this.cep = endereco.cep;
    this.logradouro = endereco.logradouro;
    this.numero = endereco.numero;
    this.bairro = endereco.bairro;
    this.complemento = endereco.complemento;
    this.descricao = endereco.descricao;
    this.padrao = endereco.padrao;
    this.cidadeId = endereco.cidadeId;
  }
}

class Cliente {
  indicadorConsumidorFinal = null;
  tipoContribuinteId = null;
  tabelaPrecoPadraoId = null;
  mensagemVenda = null;
  limiteCompra = null;
  grupoPessoaId = null;

  constructor(cliente) {
    if (cliente) {
      this.id = cliente?.id;
      this.indicadorConsumidorFinal = cliente.indicadorConsumidorFinal;
      this.tipoContribuinteId = cliente.tipoContribuinteId;
      this.tabelaPrecoPadraoId = cliente.tabelaPrecoPadraoId;
      this.mensagemVenda = cliente.mensagemVenda;
      this.limiteCompra = parseFloat(cliente.limiteCompra) || undefined;
      this.grupoPessoaId = cliente.grupoPessoaId;
    }
  }
}

class Fornecedor {
  limiteCompra = null;
  grupoPessoaId = null;

  constructor(fornecedor) {
    if (fornecedor) {
      this.id = fornecedor?.id;
      this.grupoPessoaId = fornecedor.grupoPessoaId;
      if (fornecedor.limiteCompra) {
        this.limiteCompra = parseFloat(fornecedor.limiteCompra);
      }
    }
  }
}

class Transportadora {
  ufEmbarque = null;
  localEmbarque = null;
  rntc = null;
  grupoPessoaId = null;

  constructor(transportadora) {
    if (transportadora) {
      this.id = transportadora?.id;
      this.ufEmbarque = transportadora.ufEmbarque;
      this.localEmbarque = transportadora.localEmbarque;
      this.rntc = transportadora.rntc;
      this.grupoPessoaId = transportadora.grupoPessoaId;
    }
  }
}

class Colaborador {
  dataAdmissao = null;
  funcao = null;
  vendedor = null;
  dataDemissao = null;
  grupoPessoaId = null;
  usuarioId = null;
  bancoCaixaId = null;
  comissaoPrazo = null;
  comissaoVista = null;
  descontoMaximo = null;

  constructor(colaborador) {
    if (colaborador) {
      this.id = colaborador?.id;
      this.dataAdmissao = colaborador.dataAdmissao;
      this.funcao = colaborador.funcao;
      this.vendedor = colaborador.vendedor;
      this.dataDemissao = colaborador.dataDemissao;
      this.grupoPessoaId = colaborador.grupoPessoaId;
      this.usuarioId = colaborador.usuarioId;
      this.bancoCaixaId = colaborador.bancoCaixaId;
      this.comissaoPrazo = colaborador.comissaoPrazo;
      this.comissaoVista = colaborador.comissaoVista;
      this.descontoMaximo = colaborador.descontoMaximo;
    }
  }
}

class PrestadorServico {
  profissao = null;
  grupoPessoaId = null;

  constructor(prestadorServico) {
    if (prestadorServico) {
      this.id = prestadorServico?.id;
      this.profissao = prestadorServico.profissao;
      this.grupoPessoaId = prestadorServico.grupoPessoaId;
    }
  }
}
