import { useState, createRef, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  TextField,
  IconButton,
  FormControlLabel,
  Switch,
  Button,
  Paper,
  Select,
  MenuItem,
  Tooltip,
  Autocomplete,
  ImageCropper,
  TabPanel,
  Tabs,
  Tab,
  FullscreenDialog,
} from "../../../components";
import { AddLayout } from "../../../layouts";
import {
  notification,
  ProdutosService,
  GrupoProdutosService,
  UnidadeComercialService,
  StorageService,
  NcmService,
  CestService,
  SessionService,
  EtiquetasService,
} from "../../../services";
import { InputErros, PermisoesHelper, value } from "../../../helpers";
import {
  responseErros,
  dataURLtoFile,
  userLogs,
  unformatPrice,
  voltar,
} from "../../../utils";
import {
  ProdutosSimilaresDialog,
  FormPrecoCusto,
  FormFiscal,
  FormFornecedor,
  FormGrade,
  FormKit,
  FormImpostos,
  HistoricoMovimentacao,
} from "./components";
import AddGrupoProdutos from "../../grupoProdutos/addGrupoProdutos/addGrupoProdutos.view";
import { produtoValidator } from "./middlewares";

import "./produto.css";
import { Produto } from "./entities";
import shortid from "shortid";
const notFoundImage =
  "https://siaflite-blob-bfesffcjhagbc4f6.z01.azurefd.net/imagem-publica/not-found.png";

const produtosService = new ProdutosService();
const grupoProdutosService = new GrupoProdutosService();
const etiquetasService = new EtiquetasService();
const unidadeComercialService = new UnidadeComercialService();
const storageService = new StorageService();
const sessionService = new SessionService();
const permissoesHelper = new PermisoesHelper();
const ncmService = new NcmService();
const cestService = new CestService();

const tipos = [
  { id: "simples", descricao: "Simples" },
  { id: "grade", descricao: "Grade" },
  { id: "kit", descricao: "Kit" },
];

let produtoEntity = {
  ativado: true,
  estoque: "0",
  fracionavel: false,
  precoCusto: "",
  tipo: tipos[0].id,
  origemMercadoriaId: 1,
  tipoItemId: 1,
  lerPesoPorBalanca: "Não se aplica",
  unidadeComercialCompraId: 59,
  unidadeComercialVendaId: 59,
};

const AddProduto = ({ match }) => {
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [userLog, setUserLog] = useState({});
  const [imageOverlayActive, setImageOverlayActive] = useState(false);
  const imageUpload = createRef();
  const [produto, setProduto] = useState(produtoEntity);
  // const prevStateRef = useRef(produto);
  const [configuracoes, setConfiguracoes] = useState({
    margemCalculo: "INVERSA",
  });
  const [imageProduto, setImageProduto] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [abaValue, setAbaValue] = useState(0);
  const PRECIFICACAO = produto?.tipo != "kit" ? 0 : 1;
  const KIT_FISCAL = produto?.tipo != "kit" ? 1 : 0;
  const FISCAL = 1;
  const IMPOSTOS = 2;
  const FORNECEDORES = 3;
  const GRADE = 4;
  const [listaGruposProdutos, setListaGruposProdutos] = useState([]);
  const [listaEtiquetas, setListaEtiquetas] = useState([]);
  const [listaUnidadesComerciais, setListaUnidadesComerciais] = useState([]);
  const [produtosSimilares, setProdutosSimilares] = useState([]);
  const [
    isCadastroGrupoProdutoDialogOpen,
    setIsCadastroGrupoProdutoDialogOpen,
  ] = useState(false);
  const [historicoMovimentacaoOpen, setHistoricoMovimentacaoOpen] =
    useState(false);
  const [historicoMovimentacaoList, setHistoricoMovimentacaoList] = useState(
    []
  );
  const [fornecedoresRows, setFornecedoresRows] = useState([]);
  const [kitProdutos, setKitProdutos] = useState([]);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const dadosEmpresa = sessionService.getEmpresa();
  permissoesHelper.validarPermisao("produtos-visualizar");
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const [produtosGradeList, setProdutosGradeList] = useState([]);
  const [ncmList, setNcmList] = useState([]);
  const [tabelaCest, setTabelaCest] = useState([]);
  const lerPesoPorBalancaOptions = [
    "Não se aplica",
    "Ler peso da balança",
    "Ler peso da etiqueta",
    "Ler peso da etiqueta do produto",
  ];
  const idCamposFormBasico = [
    "unidadeMedidaId",
    "grupoId",
    "generoItemId",
    "precoCompra",
    "precoMedio",
    "precoVenda",
    "estoque",
  ];
  const idCamposFormFiscal = [
    "origemId",
    "ncm",
    "cest",
    "porcentagemGpl",
    "porcentagemNacionalGpl",
    "codigoAnp",
    "valorPartidaGpl",
    "grupoTributacaoId",
  ];

  useEffect(() => {
    buscarConfiguracao();
    buscarUnidadesComerciais();
    buscarGruposProdutos();
    buscarEtiquetas();
    buscarProduto();
    if (!id) {
      produtoEntity = new Produto({
        ativado: true,
        estoque: "0",
        fracionavel: false,
        precoCusto: "",
        tipo: tipos[0].id,
        origemMercadoriaId: 1,
        tipoItemId: 1,
        lerPesoPorBalanca: "Não se aplica",
        unidadeComercialCompraId: 59,
        unidadeComercialVendaId: 59,
      });
    } else {
      buscarProduto();
    }
  }, []);

  const buscarProduto = async () => {
    if (id) {
      setLoading(true);
      const res = await produtosService.getById(id);
      if (!res.isAxiosError) {
        buscarDadosNcm(res.data.ncmId);
        buscarDadosCest(res.data.cestId);
        if (res.data.grade) {
          const estoqueProdutosGrade = res.data.grade.produtos
            .map((item) => item.estoqueProduto.estoque)
            .reduce((a, b) => a + b);
          produtoEntity = new Produto({
            ...res.data.grade.produtos[0],
            ...res.data.grade,
            codigoBarra: "",
          });
          setProdutosGradeList(res.data.grade.produtos);
          produtoEntity.setHandleChangeInput(
            "estoqueAtual",
            estoqueProdutosGrade
          );
          produtoEntity.setProdutoImagem(res.data.imagem);
          setProduto(produtoEntity);
        } else {
          produtoEntity = new Produto(res.data);
          produtoEntity.setHandleChangeInput(
            "estoqueAtual",
            res.data.estoqueProduto.estoque
          );
          setProduto(produtoEntity);
        }
        inserirValoresListaEtiquetas(produtoEntity.etiquetasProdutos);
        if (res.data.similares.length > 0) {
          res.data.similares.forEach((similar) => {
            if (similar.produto) {
              setProdutosSimilares((prevState) => [
                ...prevState,
                similar.produto,
              ]);
            }
          });
        }
        if (res.data.tipo == "kit" && res.data.kits.length > 0) {
          const kits = [];
          res.data.kits.forEach((kit) => {
            kits.push({ ...kit.produto, quantidade: kit.quantidade });
            produtoEntity.setProdutosKitIds(kit.kitProdutoId, kit.quantidade);
          });
          setKitProdutos(kits);
        } else {
          setFornecedoresRows(
            res.data.fornecedores.map((fornecedor) => ({
              ...fornecedor,
              id: fornecedor.fornecedorId,
            }))
          );
        }
      } else {
        responseErros(res);
      }
      setLoading(false);
    }
  };

  function inserirValoresListaEtiquetas(listaEtiquetasIds) {
    const idsEtiquetas =
      listaEtiquetasIds?.map((etiquetas) => ({
        id: etiquetas.etiquetaId,
      })) || [];
    produtoEntity.setProdutoEtiquetas(idsEtiquetas);
    setProduto({
      ...produtoEntity,
      etiquetasProdutos: idsEtiquetas,
    });
  }

  const buscarAjusteEstoque = async (filtros) => {
    if (historicoMovimentacaoList?.length) return;
    const filtro = {
      produtoId: id,
      dataInicial: "2000-01-01T14:22:26.987Z",
      dataFinal: new Date(),
      ...filtros,
    };
    const result = await produtosService.getRelatorioDetalhado(filtro);
    if (!result.isAxiosError) {
      const data = result.data.movimentacao[id];
      if (data && data?.itens?.length) {
        const res = data.itens.map((movimentacao) => {
          return {
            id: shortid.generate(),
            ...movimentacao,
          };
        });
        setHistoricoMovimentacaoList(res);
      }
    } else responseErros(result);
  };

  function handleAjusteEstoqueMovimentacao() {
    buscarAjusteEstoque();
    setHistoricoMovimentacaoOpen(true);
  }

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(produto);
    setUserLog(logs);
  }

  const handleCloseCadastroGrupoProduto = () => {
    buscarGruposProdutos();
    setProduto((prevState) => ({
      ...prevState,
      grupoProdutoId: null,
    }));
    setIsCadastroGrupoProdutoDialogOpen(false);
  };

  const buscarConfiguracao = () => {
    if (dadosEmpresa.configuracoes) {
      const data = dadosEmpresa.configuracoes;
      setConfiguracoes(data);
    }
  };

  const buscarDadosNcm = async (ncmId) => {
    ncmService.getById(ncmId).then((result) => {
      if (!result.isAxiosError) {
        setNcmList([result.data]);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarDadosCest = async (id) => {
    cestService.getAll({ id }).then((result) => {
      if (!result.isAxiosError) {
        setTabelaCest(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarGruposProdutos = () => {
    const filter = {
      nonPaginated: true,
      ativado: true,
    };
    grupoProdutosService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        setListaGruposProdutos(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarEtiquetas = () => {
    const filter = {
      nonPaginated: true,
      ativado: true,
      tipo: "PRODUTO",
    };
    etiquetasService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        setListaEtiquetas(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarUnidadesComerciais = () => {
    unidadeComercialService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setListaUnidadesComerciais(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const handleImagemChange = (event) => {
    let file;
    if (event.dataTransfer) {
      file = event.dataTransfer.files[0];
    } else if (event.target) {
      file = event.target.files[0];
    }
    const reader = new FileReader();
    reader.onload = function () {
      setImageProduto(reader.result);
      setIsCropping(true);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImagem = () => {
    setProduto({
      ...produto,
      imagem: null,
    });
    setImageProduto(null);
  };

  const onSaveImageClopper = (croppedFile) => {
    setIsCropping(false);
    setImageOverlayActive(false);
    const file = dataURLtoFile(croppedFile, "foto-produto");
    setImageProduto(file);
    setProduto({
      ...produto,
      imagem: croppedFile,
    });
  };

  const onHideImageCropper = () => {
    setIsCropping(false);
    setImageOverlayActive(false);
  };

  const handleAbaChange = (e, value) => {
    setAbaValue(value);
  };

  const handleChangeAutocomplete = (name, value) => {
    if (name == "tipo" && !value) {
      setAbaValue(PRECIFICACAO);
    }
    produtoEntity.setHandleChangeAutoComplete(name, value, tabelaCest);
    setProduto({ ...produtoEntity });
  };

  const tabProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  const validarCodigoBarras = async (produto) => {
    if (id) {
      const res = await produtosService.validarCodigoBarrasId(
        id,
        produto.codigoBarra
      );
      if (res.isAxiosError) {
        notification.erroCadastroApi(res.response.data.message);
        setInputErro("codigoBarra");
        setLoading(false);
        return true;
      }
    } else {
      const res = await produtosService.validarCodigoBarras(
        produto.codigoBarra
      );
      if (res.isAxiosError) {
        notification.erroCadastroApi(res.response.data.message);
        setInputErro("codigoBarra");
        setLoading(false);
        return true;
      }
    }
    return false;
  };

  const submitProduto = async (indiceSelecionado) => {
    produtoEntity.setFornecedores(fornecedoresRows);
    produtoEntity.setProdutosGrade(produtosGradeList);
    produtoEntity.setProdutosSimilaresIds(produtosSimilares);
    produtoValidator
      .validate(produtoEntity, { abortEarly: false })
      .then(async () => {
        setInputErro([]);
        setLoading(true);
        const codigoRepetido = await validarCodigoBarras(produtoEntity);
        if (codigoRepetido) return;
        const validarPrecos = await validarCustoVenda(produto);
        if (validarPrecos) return;
        if (imageProduto) {
          const formData = new FormData();
          formData.append("image", imageProduto);
          const resStorage = await storageService.addImage(formData);
          if (!resStorage.isAxiosError) {
            produtoEntity.setProdutoImagem(resStorage.data.fileName);
            if (produtoEntity.produtosGrade.length > 0) {
              for (const produtoGrade of produtoEntity.produtosGrade) {
                produtoGrade.setProdutoImagem(resStorage.data.fileName);
              }
            }
          }
        }
        produtoEntity.setProdutoEtiquetasIds();
        if (!imageProduto && produtoEntity.imagem) {
          const splitImageUrl = produtoEntity.imagem.split("/");
          produtoEntity.setProdutoImagem(splitImageUrl[4]);
        }
        if (id) {
          atualizarProduto(id, produtoEntity);
        } else {
          cadastrarProduto(produtoEntity, indiceSelecionado);
        }
      })
      .catch((err) => {
        const inputErroTemp = err?.inner?.map((item) => item.path);
        setInputErro(inputErroTemp);
        let contemErroFormBasico = false;
        let contemErroFormFiscal = false;
        let contemErroFormFornecedores = false;
        let contemErroFormGrade = false;
        for (const erro of inputErroTemp) {
          if (idCamposFormBasico.indexOf(erro) != -1) {
            contemErroFormBasico = true;
          }
          if (idCamposFormFiscal.indexOf(erro) != -1) {
            contemErroFormFiscal = true;
          }
          if (erro == "fornecedores") {
            contemErroFormFornecedores = true;
          }
          if (erro.includes("grades")) {
            contemErroFormGrade = true;
          }
          if (
            contemErroFormBasico ||
            contemErroFormFiscal ||
            contemErroFormFornecedores ||
            contemErroFormGrade
          ) {
            break;
          }
        }
        if (contemErroFormBasico) {
          setAbaValue(PRECIFICACAO);
        } else if (contemErroFormFiscal) {
          setAbaValue(FISCAL);
        } else if (contemErroFormFornecedores) {
          setAbaValue(FORNECEDORES);
        } else if (contemErroFormGrade) {
          err.errors.length = 1;
          setAbaValue(GRADE);
        }
        setLoading(false);
        inputErros.set(err);
      });
  };

  const validarCustoVenda = async (produto) => {
    if (parseFloat(produto.precoVenda) < parseFloat(produto.precoCusto)) {
      const retornoAlerta = await notification.confirmacao(
        "Atenção!",
        `Deseja salvar produto com Preço de Venda menor que Preço de Custo?`
      );
      if (!retornoAlerta.isConfirmed) {
        setInputErro("precoVenda");
        setAbaValue(PRECIFICACAO);
        setLoading(false);
      }
      return !retornoAlerta;
    }
    return false;
  };

  const inserirPrecosKit = (data) => {
    const totalCompra = data.reduce((acumulador, objetoAtual) => {
      return (
        acumulador +
        parseFloat(objetoAtual.precoCompra) * parseFloat(objetoAtual.quantidade)
      );
    }, 0);
    const totalCusta = data.reduce((acumulador, objetoAtual) => {
      return (
        acumulador +
        parseFloat(objetoAtual.precoCusto) * parseFloat(objetoAtual.quantidade)
      );
    }, 0);
    const totalVenda = data.reduce((acumulador, objetoAtual) => {
      return (
        acumulador +
        parseFloat(objetoAtual.precoVenda) * parseFloat(objetoAtual.quantidade)
      );
    }, 0);
    const produtoTemp = {
      ...produto,
      precoCusto: totalCusta,
      precoCompra: totalCompra,
      precoVenda: totalVenda,
    };
    setProduto(produtoTemp);
  };

  const cadastrarProduto = async (body, indiceSelecionado) => {
    const res = await produtosService.cadastrar(body);
    setLoading(false);
    if (!res.isAxiosError) {
      notification.cadastroSucesso();
      resetProduto();
      if (indiceSelecionado === 0) {
        voltar();
      }
    } else {
      responseErros(res);
    }
  };

  const atualizarProduto = async (id, body) => {
    const res = await produtosService.atualizar(id, body);
    setLoading(false);
    if (!res.isAxiosError) {
      notification.alteracaoSucesso();
      resetProduto();
      voltar();
    } else {
      responseErros(res);
    }
  };

  const resetProduto = () => {
    produtoEntity = new Produto({
      ativado: true,
      estoque: "0",
      fracionavel: false,
      precoCusto: "",
      tipo: tipos[0].id,
      origemMercadoriaId: 1,
      tipoItemId: 1,
      lerPesoPorBalanca: "Não se aplica",
      unidadeComercialCompraId: 59,
      unidadeComercialVendaId: 59,
    });
    setProduto({ ...produtoEntity });
    setProdutosGradeList([]);
    setFornecedoresRows([]);
  };

  const getMultipleAutocompleteValues = (valores, lista) => {
    if (valores) {
      return valores.map((valor) => value.autoComplete(lista, valor.id));
    }
    return [];
  };

  const handleInputChange = (e) => {
    produtoEntity.setHandleChangeInput(e.target.name, e.target.value);
    setProduto({ ...produtoEntity });
  };

  const handleChangeSwitch = (e) => {
    produtoEntity.setHandleChangeSwitch(e.target.name, e.target.checked);
    setProduto({ ...produtoEntity });
  };

  const handleInputChangeVenda = (e) => {
    produtoEntity.setHandleChangeInputVenda(
      e.target.name,
      unformatPrice(e.target.value),
      id,
      configuracoes
    );
    setProduto({ ...produtoEntity });
  };

  console.log(produto);

  return (
    <AddLayout
      id={id}
      title={id ? "Editar produto" : "Cadastrar produto"}
      onClickSalvar={submitProduto}
      loading={loading}
      userLog={id ? userLog : null}
      onClickLog={onClickLog}
      showUpdate={permissoesHelper.temPermisao("produtos-editar")}
      codigo={id ? `Código Nº ${produto.codigo}` : null}
      actions={
        <>
          {id && (
            <IconButton
              color="padrao"
              className="mx-3"
              onClick={handleAjusteEstoqueMovimentacao}
            >
              <i className="ph-fill ph-package" style={{ fontSize: 20 }}></i>
            </IconButton>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={produto?.ativado}
                onChange={handleChangeSwitch}
                name="ativado"
                color="primary"
              />
            }
            label={produto?.ativado ? "Ativado" : "Desativado"}
          />
        </>
      }
      // formState={{
      //   inicial: prevStateRef?.current,
      //   final: montarBodyProduto(),
      // }}
      resetFormFunction={resetProduto}
    >
      <Grid className="grid-container">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Tooltip
              title="Campo não aceita caracteres especiais"
              arrow
              placement="top"
            >
              <TextField
                id="codigoBarra"
                name="codigoBarra"
                label="Código de Barra"
                variant={produto.tipo == "grade" ? "filled" : "outlined"}
                error={inputErros.get("codigoBarra")}
                disabled={produto.tipo == "grade" ? true : false}
                value={
                  produto.tipo != "grade" && produto.codigoBarra
                    ? produto.codigoBarra
                    : ""
                }
                onChange={handleInputChange}
                fullWidth
              />
            </Tooltip>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="descricao"
              name="descricao"
              label="Descrição"
              variant="outlined"
              value={produto.descricao ? produto.descricao : ""}
              InputProps={{ inputProps: { maxLength: 50 } }}
              onChange={handleInputChange}
              error={inputErros.get("descricao")}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              id="tipo"
              name="tipo"
              disabled={id ? true : false}
              options={tipos}
              disableClearable
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => option.descricao ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  variant="outlined"
                  label="Tipo"
                  error={inputErros.get("tipo")}
                />
              )}
              onChange={(e, value) => handleChangeAutocomplete("tipo", value)}
              value={value.autoComplete(tipos, produto?.tipo)}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="grupoProdutoId"
              name="grupoProdutoId"
              options={listaGruposProdutos}
              autoHighlight
              getOptionLabel={(option) =>
                option.codigo ? `${option.codigo} - ${option.descricao}` : ""
              }
              noOptionsText={
                <Grid container spacing={1}>
                  <Grid item xs={7} className="mt-2" fullWidth>
                    Nenhum grupo encontrado
                  </Grid>
                  <Grid item xs={5} className="d-flex justify-content-end">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setIsCadastroGrupoProdutoDialogOpen(true)}
                    >
                      Adicionar
                    </Button>
                  </Grid>
                </Grid>
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Grupo de Produto"
                  required
                  variant="outlined"
                  error={inputErros.get("grupoProdutoId")}
                />
              )}
              onChange={(e, value) =>
                handleChangeAutocomplete("grupoProdutoId", value)
              }
              value={value.autoComplete(
                listaGruposProdutos,
                produto.grupoProdutoId
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled={produto.tipo == "grade"}
              size="large"
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              Similar
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              id="unidadeComercialCompraId"
              name="unidadeComercialCompraId"
              required
              options={listaUnidadesComerciais}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => option.descricao ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Unidade Comercial Compra"
                  variant="outlined"
                  required
                  error={inputErros.get("unidadeComercialCompraId")}
                />
              )}
              onChange={(_, value) => {
                setInputErro([]);
                handleChangeAutocomplete("unidadeComercialCompraId", value);
              }}
              value={value.autoComplete(
                listaUnidadesComerciais,
                produto?.unidadeComercialCompraId
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              id="unidadeComercialVendaId"
              name="unidadeComercialVendaId"
              options={listaUnidadesComerciais}
              noOptionsText="Sem opções"
              autoHighlight
              required
              getOptionLabel={(option) => option.descricao ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Unidade Comercial Venda"
                  variant="outlined"
                  error={inputErros.get("unidadeComercialVendaId")}
                  required
                />
              )}
              onChange={(_, value) => {
                setInputErro([]);
                handleChangeAutocomplete("unidadeComercialVendaId", value);
              }}
              value={value.autoComplete(
                listaUnidadesComerciais,
                produto?.unidadeComercialVendaId
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="conversorEntrada"
              name="conversorEntrada"
              label="Conversor de Entrada"
              variant="outlined"
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputProps: { min: 0 } }}
              value={produto.conversorEntrada ?? ""}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="estoque"
              name="estoque"
              label="Estoque"
              variant="filled"
              disabled
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputProps: { min: 0 } }}
              value={produto?.estoqueAtual ?? ""}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              label="Fracionável?"
              id="fracionavel"
              name="fracionavel"
              value={produto.fracionavel ?? false}
              required
              onChange={handleInputChange}
            >
              <MenuItem value={true}>Sim</MenuItem>
              <MenuItem value={false}>Não</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="lerPesoPorBalanca"
              name="lerPesoPorBalanca"
              options={lerPesoPorBalancaOptions}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => option ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ler Peso por Balança"
                  variant="outlined"
                />
              )}
              onChange={handleInputChange}
              value={produto.lerPesoPorBalanca ?? ""}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="etiquetasProdutos"
              name="etiquetasProdutos"
              options={listaEtiquetas}
              noOptionsText="Sem opções"
              autoHighlight
              renderOption={(props, option) => {
                return option ? (
                  <li {...props}>
                    <div className="d-flex align-items-center gap-2 w-100">
                      <i
                        className="ph-fill ph-bookmark-simple"
                        style={{ color: option.cor, fontSize: 18 }}
                      />
                      <div>{option.descricao}</div>
                    </div>
                  </li>
                ) : (
                  ""
                );
              }}
              getOptionLabel={(option) => {
                return option ? option.descricao : "";
              }}
              onChange={(_, newValue) => {
                produtoEntity.setProdutoEtiquetas(newValue);
                setProduto({
                  ...produto,
                  etiquetasProdutos: newValue,
                });
              }}
              value={getMultipleAutocompleteValues(
                produto?.etiquetasProdutos,
                listaEtiquetas
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Etiquetas"
                  required
                  error={inputErros.get("etiquetasProdutos")}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          className="media-container"
          onMouseOver={() => setImageOverlayActive(true)}
          onMouseLeave={() => setImageOverlayActive(false)}
        >
          <img
            className="media"
            src={produto.imagem ?? notFoundImage}
            alt="imagem"
          />
          <input
            accept="image/*"
            className="logo-image-input"
            ref={imageUpload}
            type="file"
            onChange={(event) => handleImagemChange(event)}
          />
          {imageOverlayActive ? (
            <div className="edit-image-container">
              <IconButton
                size="medium"
                ariaLabel="Alterar imagem"
                onClick={() => imageUpload.current.click()}
              >
                <i
                  className="edit-image-icon ph-fill ph-camera"
                  style={{ color: "#efefef", fontSize: 30 }}
                ></i>
              </IconButton>
              {produto.imagem && (
                <IconButton
                  size="medium"
                  ariaLabel="Remover imagem"
                  onClick={() => handleRemoveImagem()}
                >
                  <i
                    className="edit-image-icon ph-fill ph-trash"
                    style={{ color: "#efefef", fontSize: 30 }}
                  ></i>
                </IconButton>
              )}
            </div>
          ) : (
            ""
          )}
          {isCropping && (
            <ImageCropper
              image={imageProduto}
              open={isCropping}
              onSave={(croppedFile) => onSaveImageClopper(croppedFile)}
              onHide={() => onHideImageCropper()}
            />
          )}
        </Grid>
      </Grid>
      <hr className="mt-5" />
      <Grid container spacing={2} className="mt-4">
        <Grid item xs={12} className="mb-3">
          <span className="fw-bold fw-bolder">DETALHES DO PRODUTO</span>
        </Grid>
        <Grid item xs={12}>
          <Paper className="mb-3">
            {produto.tipo === "kit" && (
              <>
                <Tabs
                  value={abaValue}
                  onChange={handleAbaChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  scrollButtons="auto"
                >
                  <Tab label="Kit" {...tabProps(KIT_FISCAL)} />
                  <Tab label="Precificação" {...tabProps(PRECIFICACAO)} />
                </Tabs>
              </>
            )}
            {produto.tipo !== "kit" && (
              <>
                <Tabs
                  value={abaValue}
                  onChange={handleAbaChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  scrollButtons="auto"
                >
                  <Tab label="Precificação" {...tabProps(PRECIFICACAO)} />
                  <Tab label="Fiscal" {...tabProps(KIT_FISCAL)} />
                  <Tab label="Impostos" {...tabProps(IMPOSTOS)} />
                  <Tab label="Fornecedores" {...tabProps(FORNECEDORES)} />
                  {/* <Tab label="Campos Personalizados" {...tabProps(CAMPOS_PERSONALIZADOS)} /> */}
                  {produto.tipo === "grade" ? (
                    <Tab label="Grade" {...tabProps(GRADE)} />
                  ) : null}
                </Tabs>
              </>
            )}
          </Paper>
          <TabPanel value={abaValue} index={PRECIFICACAO}>
            <FormPrecoCusto
              produto={produto}
              inputErros={inputErros}
              handleInputChangeVenda={handleInputChangeVenda}
            />
          </TabPanel>
          <TabPanel value={abaValue} index={KIT_FISCAL}>
            {produto.tipo == "kit" ? (
              <FormKit
                kitProdutos={kitProdutos}
                setKitProdutos={setKitProdutos}
                produto={produto}
                setProduto={setProduto}
                inserirPrecosKit={inserirPrecosKit}
                inputErros={inputErros}
                produtoEntity={produtoEntity}
              />
            ) : (
              <FormFiscal
                produto={produto}
                setProduto={setProduto}
                inputErros={inputErros}
                setLoading={setLoading}
                ncmList={ncmList}
                setNcmList={setNcmList}
                tabelaCest={tabelaCest}
                setTabelaCest={setTabelaCest}
                loadingAutoComplete={loadingAutoComplete}
                setLoadingAutoComplete={setLoadingAutoComplete}
                handleChangeAutocomplete={handleChangeAutocomplete}
                handleInputChange={handleInputChange}
                produtoEntity={produtoEntity}
              />
            )}
          </TabPanel>
          <TabPanel value={abaValue} index={IMPOSTOS}>
            <FormImpostos
              produto={produto}
              handleChangeInput={handleInputChangeVenda}
            />
          </TabPanel>
          <TabPanel value={abaValue} index={FORNECEDORES}>
            <FormFornecedor
              fornecedoresRows={fornecedoresRows}
              setFornecedoresRows={setFornecedoresRows}
            />
          </TabPanel>
          {/* <TabPanel value={abaValue} index={CAMPOS_PERSONALIZADOS}>
            <FormCamposPersonalizados
              camposPersonalizados={camposPersonalizados}
              setCamposPersonalizados={setCamposPersonalizados}
              listaCampoPersonalizado={listaCampoPersonalizado}
              setListaCampoPersonalizado={setListaCampoPersonalizado}
            />
          </TabPanel> */}
          <TabPanel value={abaValue} index={GRADE}>
            <FormGrade
              setLoading={setLoading}
              produto={produto}
              setProduto={setProduto}
              produtosGradeList={produtosGradeList}
              setProdutosGradeList={setProdutosGradeList}
              buscarProduto={buscarProduto}
              produtoEntity={produtoEntity}
            />
          </TabPanel>
        </Grid>
      </Grid>
      <FullscreenDialog
        open={isCadastroGrupoProdutoDialogOpen}
        handleClose={handleCloseCadastroGrupoProduto}
        content={
          <AddGrupoProdutos
            isFullscreenDialog
            handleCloseFullscreenDialog={handleCloseCadastroGrupoProduto}
          />
        }
      />
      <HistoricoMovimentacao
        open={historicoMovimentacaoOpen}
        setOpen={setHistoricoMovimentacaoOpen}
        historicoMovimentacaoList={historicoMovimentacaoList}
      />
      <Dialog
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        PaperComponent="div"
        open={openDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Card className="p-3">
            <CardHeader title="Produtos Similares" />
            <CardContent className="p-3">
              <ProdutosSimilaresDialog
                produtosSimilares={produtosSimilares}
                setProdutosSimilares={setProdutosSimilares}
                openDialog={openDialog}
                id={id}
              />
            </CardContent>
            <CardActions>
              <Button
                color="inherit"
                variant="contained"
                onClick={() => setOpenDialog(false)}
              >
                Fechar
              </Button>
            </CardActions>
          </Card>
        </DialogContent>
      </Dialog>
    </AddLayout>
  );
};

export default AddProduto;
