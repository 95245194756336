import * as Yup from "yup";

const etiquetasValidator = Yup.object().shape({
  ativado: Yup.boolean().nullable(),
  tipo: Yup.string().required("Tipo"),
  descricao: Yup.string().required("Descrição"),
  cor: Yup.string()
    .matches(
      /(#F50007|#0EF54B|#1F6223|#F56400|#6C0084|#84002D|#4E5EF2|#007584|#000E84)/,
      { message: "Cor compatível" }
    )
    .required("Cor"),
});

export default etiquetasValidator;
